import { useState, useEffect } from "react"
import styled from "styled-components"
import "../styles/QuotePage.css"
import shipping from "../assets/images/shipping-vid.mp4"
import Logo from "../components/Logo"
import Navbar from "../components/Navbar"
import { toast } from "react-hot-toast"

// Styled Components for Quote Section
const QuoteSection = styled.section`
  padding: 2rem 0;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  border-radius: 10px;
  margin: 2rem auto;
  max-width: 1200px;
  width: 90%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    width: 95%;
    padding: 1.5rem 0;
  }
`

const QuoteInner = styled.div`
  width: 90%;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    width: 95%;
  }
`

const QuoteTitle = styled.h2`
  text-align: center;
  color: white;
  margin-bottom: 2rem;
  font-size: 2rem;
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`

const InputGroupTop = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.75rem;
  }
`

const CountrySelect = styled.select`
  flex: 1;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: white;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: #0070f3;
  }
  
  @media (max-width: 768px) {
    width: 100%;
  }
`

const InputGroupMiddle = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.75rem;
  }
`

const WeightInput = styled.input`
  flex: 1;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: #0070f3;
  }
  
  &:disabled {
    background-color: #f0f0f0;
    color: #666;
  }
  
  @media (max-width: 768px) {
    width: 90%;
  }
`

const InputGroupBottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const TotalLabel = styled.label`
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
`

const TotalInput = styled.input`
  padding: 0.75rem 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: #f0f0f0;
  color: #333;
  
  &:disabled {
    color: #333;
  }
`

function QuotePage() {
  const [originCountry, setOriginCountry] = useState("")
  const [destinationCountry, setDestinationCountry] = useState("")
  const [shipmentWeightKg, setShipmentWeightKg] = useState("")
  const [shipmentWeightLbs, setShipmentWeightLbs] = useState("")
  const [totalCost, setTotalCost] = useState("")

  useEffect(() => {
    if (shipmentWeightKg === "") {
      setShipmentWeightLbs("")
      setTotalCost("")
      return
    }

    const weightKg = Number.parseFloat(shipmentWeightKg)
    if (isNaN(weightKg) || weightKg <= 0) {
      setShipmentWeightLbs("")
      setTotalCost("")
      return
    }

    const lbsEquivalent = weightKg * 2.20462 // 1 kilogram = 2.20462 pounds
    setShipmentWeightLbs(lbsEquivalent.toFixed(2)) // Round to 2 decimal places

    // Check for weight threshold (e.g., 200 kg)
    if (weightKg > 200) {
      toast.error("Weight exceeds the maximum allowed limit.")
      setTotalCost("")
      return
    }

    // Calculate shipping rate based on origin country and weight
    let shippingRate = 0

    if (originCountry === "UK") {
      // UK Shipping Rates
      if (weightKg <= 2) {
        shippingRate = 30
      } else if (weightKg <= 4) {
        shippingRate = 55
      } else if (weightKg <= 6) {
        shippingRate = 75
      } else if (weightKg <= 8) {
        shippingRate = 90
      } else if (weightKg <= 11) {
        shippingRate = 110
      } else if (weightKg <= 14) {
        shippingRate = 130
      } else if (weightKg <= 16) {
        shippingRate = 150
      } else if (weightKg <= 18) {
        shippingRate = 170
      } else {
        shippingRate = 200
      }
    } else if (originCountry === "US") {
      // US Shipping Rates: $10 per pound
      const pounds = Math.ceil(lbsEquivalent) // Round up to the nearest pound
      shippingRate = pounds * 10 // $10 per pound
    }

    // Calculate total cost
    setTotalCost(shippingRate)
  }, [shipmentWeightKg, originCountry]) // Update when shipmentWeightKg or originCountry changes

  return (
    <div className="main-quote-container">
      <Logo />
      <div className="schedule-home-section">
        <video loading="lazy" src={shipping} autoPlay loop muted preload="none" />
        <div className="schedule-overlay">
          <div className="schedule-content">
            <h1>Rates and Pricings</h1>
            <p>Calculate the exact amount you would pay to have your order shipped</p>
          </div>
        </div>
      </div>

      {/* Updated Quote Section with Styled Components */}
      <QuoteSection>
        <QuoteInner>
          <QuoteTitle>Get a quote</QuoteTitle>
          <InputGroupTop>
            <CountrySelect value={originCountry} onChange={(e) => setOriginCountry(e.target.value)}>
              <option value="">Select Origin Country</option>
              <option value="UK">UK</option>
              <option value="US">US</option>
            </CountrySelect>
            <CountrySelect value={destinationCountry} onChange={(e) => setDestinationCountry(e.target.value)}>
              <option value="">Select Destination Country</option>
              <option value="Ghana">Ghana</option>
            </CountrySelect>
          </InputGroupTop>

          <InputGroupMiddle>
            <WeightInput
              type="number"
              placeholder="Weight (kg)"
              value={shipmentWeightKg}
              onChange={(e) => setShipmentWeightKg(e.target.value)}
            />
            <WeightInput type="number" placeholder="Weight (lbs)" value={shipmentWeightLbs} disabled />
          </InputGroupMiddle>

          <InputGroupBottom>
            <TotalLabel>Total</TotalLabel>
            <TotalInput type="text" placeholder="Total Cost" value={totalCost ? `$${totalCost}` : ""} disabled />
          </InputGroupBottom>
        </QuoteInner>
      </QuoteSection>

      <section className="responsive-tables">
        <h2>Popular Weight Details</h2>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Weight (lbs)</th>
                <th>Weight (Kg)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cell Phone</td>
                <td>1.00</td>
                <td>0.45</td>
              </tr>
              <tr>
                <td>Laptop</td>
                <td>5.00</td>
                <td>2.27</td>
              </tr>
              <tr>
                <td>Clothing</td>
                <td>0.40</td>
                <td>0.18</td>
              </tr>
              <tr>
                <td>Shoe</td>
                <td>2.00</td>
                <td>0.91</td>
              </tr>
              <tr>
                <td>Camera - Canon EOS Rebel</td>
                <td>3.50</td>
                <td>1.59</td>
              </tr>
              <tr>
                <td>Tablet</td>
                <td>1.50</td>
                <td>0.68</td>
              </tr>
              <tr>
                <td>PS5/Xbox</td>
                <td>9.00</td>
                <td>4.08</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <Navbar />
    </div>
  )
}

export default QuotePage

