import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/HomeBoxes.css'; // External CSS file for styling
import { FaHome, FaDollarSign, FaMoneyBillWave , FaInfoCircle, FaEnvelope, FaProcedures, FaArrowAltCircleRight, FaMap } from 'react-icons/fa'; // Example icons

const HomeBoxes = () => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
      navigate(path); // Navigate to the given path
    };

  return (
    <div className="three-boxes-container">
      <div className="boxx" onClick={() => handleNavigation('/process')}>
        <div className='boxxinner'>
        <FaArrowAltCircleRight className="box-icon" />
        <h3>Process</h3>
        <p>View our process</p>
        </div>
      </div>
      <div className="boxx" onClick={() => handleNavigation('/quote')}>
      <div className='boxxinner'>
        <FaMoneyBillWave className="box-icon" />
        <h3>Rates</h3>
        <p>View our rates</p>
      </div>
      </div>
      <div className="boxx" onClick={() => handleNavigation('/schedule')}>
      <div className='boxxinner'>
        <FaInfoCircle className="box-icon" />
        <h3>Drop Dates</h3>
        <p>View drop dates</p>
      </div>
      </div>
    </div>
  );
};

export default HomeBoxes;
